(function($) {
	// Add your custom JS here.
	var mtop ;
	var max =  -1;
	function setHeight(element){
            // this will give you maximum height of your div
            // console.log('element: ',element);
        $(document).find(element).each(function() {
            var h = $(this).height();
            max = h > max ? h : max;
        });
            // varible-div is the division whose height keeps changeing
        $(element).height(max);
        max =  -1;
}

	$( document ).ready(function() {
			slickMobile();
			setHeightAuto();
			$(window).on('resize', function() {
				setHeightAuto();
			}).resize();
		// if($(window).width() >= 768){
			setHeight('#section-categories .categories-desc');
			setHeight('.col-categories .c-block-categories');
			setHeight('.col-categories .categories-item .categories-desc');
			setHeight('.col-categories .categories-item .categories-school.categories-school-about');
			setHeight('.col-categories .tab-content tab-pane.active');
			var set_height = $('.col-categories:first-child .tab-content').height();
			// alert('set_height: '+set_height);
			$('.col-categories .list-awards').css('max-height',(set_height-24)+'px');
		// }

		var fixed = document.getElementById('navbarNavDropdown');
		fixed .addEventListener('touchmove', function(e) {
        e.preventDefault();
		}, false);

		$('[data-toggle="tooltip"]').tooltip();

		// MENU MOBILE
		$('body').on('click','#navbar-toggler-custom', function(e){
			e.preventDefault();
			var $menu = $('.block-main-menu');
			// console.log('${window.innerHeight}: ',window.innerHeight);
			// console.log('$(window).height(): ', $(window).height());
			var h = window.innerHeight-62;
			$(this).toggleClass('collapsed');
			if($menu.hasClass( "show-slow" )){
				$('.block-main-menu').removeClass( "show-slow" );
				$menu.animate({
			    height: 0
			  }, 300, function() {
			  });
			}else{
				$('.block-main-menu').addClass( "show-slow" );
				$menu.animate({
			    height: h
			  }, 300, function() {
			  });
			}
			
		});

		// Close Notify Bar
		$('body').on('click','#close-notify', function(e){
			e.preventDefault();
			closeNotify();
			$('#notify-bar').slideUp("slow", function() { $('#ur_id').remove();});
			$('body').removeAttr('id');
		});

		function closeNotify(){
	    var str = '&action=close_notify_ajax';
	    $.ajax({
	        type: "POST",
	        dataType: "html",
	        url: ajax_posts.ajaxurl,
	        data: str,
	        success: function(data){

	        },
	        error : function(jqXHR, textStatus, errorThrown) {
	            $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
	        }

	    });
	    return false;
		}

		// Scroll to element
		var reinit = false;
		var $categories_content, s_top;
		if (location.href.indexOf("#") != -1) {
			if(($('#section_categories').length) && ($(window).width() <= 768)){
				$('#categories_content').on('init', function(event, slick){
				    // console.log("initialized");
				    $('.col-categories .list-awards').css('max-height',(set_height-24)+'px');
				    if(!reinit){
				   //  	reinit = true;
				   //  	var items = $('#section_categories .categories-content .slick-slide');
							// var lastItem = $('#section_categories .categories-content .slick-slide'+window.location.hash);
							// var index = items.index(lastItem);
							// $('#categories_content').slick('slickGoTo', {index: 4 }) ;
				    }
				    if($('#section_latest_announcements').length){
				    	s_top = 20;
				    }else{
				    	s_top = 110;
				    }
				    $('html,body').animate({
						  scrollTop: $(window.location.hash).offset().top-s_top
						});
				});


				
			}else{
				$('html,body').animate({
				  scrollTop: $(window.location.hash).offset().top-80
				});
				cat-semi-autonomous
			}  
			
		}

		// SECLECT 2
		$('.bj-select2').select2({
			theme: 'bootstrap4'
		});

		// PDF Fancybox
		
		if($(window).width() <= 768){
			// $('.nav-tabs').addClass('owl-carousel owl-theme');
			// $('.nav-tabs').owlCarousel();

			$('.promotions_slider').slick({
			  dots: false, 
			  arrows: true,
			  variableWidth: true,
			  infinite: true,
			  speed: 500,
			  slidesToShow: 1,
		    slidesToScroll: 1
		  });
		}

		// $(".gallery-contents").justifiedGallery();
		if($(window).width() >= 768){
			var $grid = $('.gallery-contents').masonry({
		     itemSelector: '.grid-item',
			  gutter: 10
			});
		}else{
			$('.gallery-contents').slick({
			  dots: true, 
			  arrows: false,
			  variableWidth: true,
			  infinite: true,
			  speed: 500,
			  slidesToShow: 1,
		    slidesToScroll: 1
		  });

			// Slider About the Competition - Categories on Mobile
			$categories_content = $('#categories_content').slick({
			  dots: false, 
			  arrows: true,
			  variableWidth: true,
			  infinite: true,
			  speed: 500,
			  slidesToShow: 1,
		    slidesToScroll: 1
		  });

		  
		}

		$('.left-selling-slider').slick({
		  dots: true, 
		  arrows: false,
		  variableWidth: false,
		  infinite: true,
		  speed: 500,
		  slidesToShow: 1,
	    slidesToScroll: 1
	  });

		
		jQuery.noConflict();

		if($(window).width() >= 768){
			$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
			    $grid.masonry('layout');
			});
		}


		$('#back-to-top').on('click', function(e){
			e.preventDefault();
		    $('html, body').animate({
		        scrollTop: 0
		    }, 1000);
		});

		$('body').on('click','#scroll-down', function(e){
			e.preventDefault();
			$('html, body').animate({ scrollTop: $('#section_selling').offset().top }, 'slow');
		})

		// Loadmore MEDIA Page

	});

	// Homepage slideshow
	var cur_video ;
	$('.slideshow-items').on('init', function(event, slick,currentSlide){
		cur_video = $('.slick-active video').get(0);
    if (cur_video != null) {
      cur_video.play();
    }
	});
	$('.slideshow-items').slick({
	  dots: true, 
	  infinite: true,
	  speed: 500,
	  arrows: false,
	  fade: true,
	  adaptiveHeight: true,
	  cssEase: 'linear'
	});

	$('.slideshow-items').on('beforeChange', function(event, slick, currentSlide){
    cur_video = $('.slick-active video').get(0);
    if (cur_video != null) {
      cur_video.pause();
    }
    // $('#slide-video').play();
	});

	$('.slideshow-items').on('afterChange', function(event, slick, currentSlide){
    cur_video = $('.slick-active video').get(0);
    if (cur_video != null) {
      cur_video.play();
    }
	});
	// $('.your-element').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	//   console.log(nextSlide);
	// });

	// Categories slider
	$('.challenge-award-items').slick({
	  dots: false, 
	  infinite: true,
	  speed: 500,
	  cssEase: 'linear',
	  touchThreshold: 50,
	  slidesToShow: 4,
	  mobileFirst: true,
	  variableWidth: true, 
    slidesToScroll: 1,
	  responsive: [
	  	{
	      breakpoint: 1200,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 900,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 200,
	      settings: {
	        slidesToShow: 1,
	        variableWidth: true,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});


	$('.categories-items').slick({
	  dots: false, 
	  infinite: true,
	  speed: 500,
	  cssEase: 'linear',
	  touchThreshold: 50,
	  slidesToShow: 4,
	  mobileFirst: true,
    slidesToScroll: 1,
	  responsive: [
	  	{
	      breakpoint: 1200,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 900,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 200,
	      settings: {
	        slidesToShow: 1,
	        variableWidth: true,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});

	$('.social-items').slick({
	  dots: true,
	  touchThreshold: 50, 
	  infinite: true,
	  speed: 500,
	  cssEase: 'linear',
	  slidesToShow: 4,
	  mobileFirst: true,
    slidesToScroll: 1,
	  responsive: [
	  	{
	      breakpoint: 1200,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 900,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 680,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        variableWidth: true,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 200,
	      settings: {
	        slidesToShow: 1,
	        variableWidth: true,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});

	// latest-new-items
	$('.latest-new-items').slick({
	  centerMode: true,
	  centerPadding: '30px',
	  slidesToShow: 1,
	  variableWidth: true,
	  focusOnSelect: true,
	  mobileFirst: true,
	  responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        centerMode: true,
	        centerPadding: '20px',
	        slidesToShow: 1
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        variableWidth: false,
	        centerMode: false,
	        centerPadding: '10px',
	        slidesToShow: 1
	      }
	    },
	    {
	      breakpoint: 300,
	      settings: {
	      	centerPadding: '5px',
	        variableWidth: false,
	        adaptiveHeight: true,
	        centerMode: false,
	        slidesToShow: 1
	      }
	    }
	  ]
	});


	// HALL OF FAME
	$('.presentation-award-items, .record-award-items').slick({
	  dots: false, 
	  infinite: true,
	  speed: 500,
	  cssEase: 'linear',
	  touchThreshold: 50,
	  slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
	variableWidth: true,
	  responsive: [
	  	{
	      breakpoint: 1200,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 1024,
	      slidesToShow: 4,
	      settings: {
	        slidesToShow: 4,
	        slidesToScroll: 1,
	        infinite: true,
	      }
	    },
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 450,
	      settings: {
	        slidesToShow: 1,
	        variableWidth: true,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 250,
	      settings: {
	        slidesToShow: 1,
	        variableWidth: true,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});

	$('select#filter-presentation').on('change', function() {
    var filterClass = $(this).find(":selected").val();
    $('.presentation-award-items').slick('slickUnfilter');
    $('.presentation-award-items').slick('slickFilter', '.filter-'+filterClass);
	});

	$('select#filter-challenge').on('change', function() {
    var filterClass = $(this).find(":selected").val();
    $('.challenge-award-items').slick('slickUnfilter');
    $('.challenge-award-items').slick('slickFilter', '.filter-'+filterClass);
	});



	// Countdown
	if ($('.bj-countdown').length) {
		var day_s, hours_s, minutes_s, seconds_s;
		var time = $('.bj-countdown').data("time");
		var countDownDate = new Date(time).getTime();
		var x = setInterval(function () {
			var now = new Date().getTime();
			var distance = countDownDate - now;
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			if (days > 0) {
				day_s = "<span><i>" + days + "</i><b>Days</b></span>";
			} else {
				day_s = "";
			}
			var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
			if (hours >= 0) {
				hours_s = "<span><i>" + hours + "</i><b>hours</b></span>";
			}
			var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
			if (minutes >= 0) {
				minutes_s = "<span><i>" + minutes + "</i><b>minutes</b></span>";
			}
			// var seconds = Math.floor(distance % (1000 * 60) / 1000);
			// if (seconds >= 0) {
			// 	seconds_s = "<span><b>SECONDS</b><i>" + seconds + "</i></span>";
			// }
			document.getElementById("countdown-slide").innerHTML = day_s + " " + hours_s + "" + minutes_s + "";
			if (distance < 0) {
				clearInterval(x);
				$('.bj-countdown').remove();
			}
			// clearInterval(x);
		}, 1000);
	}

	// LEARNING JOURNEY
	$('.slider-for').slick({
	  slidesToShow: 1, 
	  slidesToScroll: 1,
	  arrows: false,
	  fade: false,
	  asNavFor: '.slider-nav',
	  touchThreshold: 50,
	  adaptiveHeight: true,
	  mobileFirst: true,
	  responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        arrows: false,
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        arrows: true,
	      }
	    },
	    {
	      breakpoint: 300,
	      settings: {
	        arrows: true,
	      }
	    }
	  ]
	}).promise().done(function() {
		if($(this).height() < 100) {
			$(this).addClass('hide_button');
		} else {
			$(this).removeClass('hide_button');
		}
	});

	  $('.slider-for').on('afterChange', function(event, slick, currentSlide, nextSlide){
		if($(this).height() < 100) {
			$(this).addClass('hide_button');
		} else {
			$(this).removeClass('hide_button');
		}
	  });
	$('.slider-nav').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  asNavFor: '.slider-for',
	  dots: false,
	  arrows: false,
	  focusOnSelect: true,
	  touchThreshold: 50,
	  centerPadding: '15px',
	  variableWidth: true,
  	cssEase: 'linear',
  	swipe: true,
  	swipeToSlide: true,
	  mobileFirst: true,
	  responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        centerMode: true,
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        centerMode: true,
	        arrows: true,
	      }
	    },
	    {
	      breakpoint: 300,
	      settings: {
	        centerMode: false,
	        arrows: true,
	      }
	    }
	  ]
	});

	// click copy text
	function CopyToClipboard(containerid) {
		var text = document.getElementById(containerid).value;
    var listener = function(ev) {
			 ev.clipboardData.setData("text/plain", text);
			 ev.preventDefault();
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
	}

	$('body').on('click','#copy-passcode', function(e){
		e.preventDefault();
		CopyToClipboard('passcode-id');
	});

	$('body').on('click','#copy-meeting-id', function(e){
		e.preventDefault();
		CopyToClipboard('metting-id');
	});


	//  About the competition Page

	function load_faqs(pageNumber, ppp, max_page){
	    pageNumber++;
	    var str = '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_faq_ajax';
	    $.ajax({
	        type: "POST",
	        dataType: "html",
	        url: ajax_posts.ajaxurl,
	        data: str,
	        success: function(data){
	            var $data = $(data);
	            if($data.length){
                $('#section_faq .faq-contents').append($data);
	              $(".more_faq").attr("disabled",false);
	              if(max_page <= pageNumber){
	              	$('#more_faq').remove();
	              }
	            } else{
	            	$('#more_faq').remove();
	            }
	        },
	        error : function(jqXHR, textStatus, errorThrown) {
	            $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
	        }

	    });
	    return false;
	}

	$(".more_faq").on("click",function(){ 
	    $(this).attr("disabled",true); 
	    let page = $(this).attr('data-page');
	    let max_page = $(this).attr('data-max-page');
	    let per_page = $(this).data('per-page');
	    load_faqs( page, per_page, max_page);
	    $(this).attr('data-page',parseInt(page)+1); 
	});
	function setHeightAuto() {
		if($('.js-set-height_item').length === 0) return;
		$('.js-set-height_item').css('height', '');
		$('.js-set-height').each(function() {
			let max_height_promotion = 0;
			$(this).find('.js-set-height_item').each(function() {
				let heightitem = $(this).outerHeight();

				if(heightitem > max_height_promotion) {
				max_height_promotion = heightitem;
				}
			});

			$(this).find('.js-set-height_item').css('height', max_height_promotion);
			
		});
	}
	function load_las(pageNumber, ppp, max_page){
	    pageNumber++;
	    var str = '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_las_ajax';
	    $.ajax({
	        type: "POST",
	        dataType: "html",
	        url: ajax_posts.ajaxurl,
	        data: str,
	        success: function(data){
	            var $data = $(data);
	            if($data.length){
                $('#la-contents ').append($data);
	              $(".more_la").attr("disabled",false);
	              if(max_page <= pageNumber){
	              	$('#more_la').remove();
	              }
	            } else{
	            	$('#more_la').remove();
	            }
	        },
	        error : function(jqXHR, textStatus, errorThrown) {
	            $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
	        }

	    });
	    return false;
	}

	$(".more_la").on("click",function(){ 
	    $(this).attr("disabled",true); 
	    let page = $(this).attr('data-page');
	    let max_page = $(this).attr('data-max-page');
	    let per_page = $(this).data('per-page');
	    load_las( page, per_page, max_page);
	    $(this).attr('data-page',parseInt(page)+1); 
	});


	// LOADMORE CATEGORY
	function load_posts(cat, pageNumber, ppp, block_show, index, max_page){
	    pageNumber++;
	    var str = '&cat=' + cat + '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_post_ajax';
	    $.ajax({
	        type: "POST",
	        dataType: "html",
	        url: ajax_posts.ajaxurl,
	        data: str,
	        success: function(data){
	            var $data = $(data);
	            if($data.length){
                $(block_show).append($data).masonry( 'appended', $data );
	              $(".more_gallery").attr("disabled",false);
	              if(max_page <= pageNumber){
	              	$('#more_posts_'+index).remove();
	              }
	            } else{
	            	$('#more_posts_'+index).remove();
	            }
	        },
	        error : function(jqXHR, textStatus, errorThrown) {
	            $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
	        }

	    });
	    return false;
	}

	$(".more_gallery").on("click",function(){ 
	    $(this).attr("disabled",true); 
	    let cat_id = $(this).data('category');
	    let page = $(this).attr('data-page');
	    let max_page = $(this).attr('data-max-page');
	    let index = $(this).attr('data-index');
	    let per_page = $(this).data('per-page');
	    let block_show = $(this).data('id');
	    load_posts(cat_id, page, per_page, block_show, index, max_page);
	    $(this).attr('data-page',parseInt(page)+1); 
	});
	function slickMobile() {
		if($('.js-slick_mobile').length === 0) return;
		$(window).on('resize', function() {
			if($(window).width() < 768 ) {
				if(!$('.js-slick_mobile').hasClass('slick-initialized')) {
					$('.js-slick_mobile').slick({
						dots: false, 
						arrows: true,
						variableWidth: true,
						infinite: true,
						speed: 500,
						slidesToShow: 1,
					  	slidesToScroll: 1,
					  	centerMode: true,
					});
				}
				
			} else {
				if($('.js-slick_mobile').hasClass('slick-initialized')) {
					$('.js-slick_mobile').slick('unslick');
				}
			}
		}).resize();
	}

})( jQuery );




